import EmptyState from '@/components/empty-state/empty-state';
import ErrorStateMsg from '@/components/error-state-msg/error-state-msg';
import LoadingSpinner from '@/components/loading-spinner/loading-spinner';
import Table from '@/components/table/table';
import { getErrorMessage } from '@/utils/functions';
import { useMediaQuery } from '@chakra-ui/react';

type Props = {
  data: any[];
  error: any;
  loading: boolean;
  headings: string[];
  emptyMessage?: string;
  MobileComponent: React.ComponentType<{ data: any[]; onRowClick?: (row: any) => void }>;
  onRowClick?: (row: any) => void;
};

const TableListRenderer = ({ data, error, loading, onRowClick, headings, MobileComponent, emptyMessage }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorStateMsg msg={getErrorMessage(error)} />;
  }

  if (data?.length <= 0) {
    return <EmptyState message={emptyMessage || 'You have no record of Orders yet'} />;
  }

  if (isMobile) {
    return <MobileComponent onRowClick={onRowClick} data={data} />;
  }

  return <Table onRowClick={onRowClick} headings={headings} data={data} />;
};

export default TableListRenderer;
