import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { CountrySelector, defaultCountries, parseCountry, usePhoneInput } from 'react-international-phone';

import React from 'react';

type FormPhoneInputProps<T extends FieldValues> = {
  label?: string;
  type?: string;
  id?: string;
  name: FieldPath<T>;
  placeholder?: string;
  isDisabled?: boolean;
  rules?: RegisterOptions<T>;
  control?: Control<T>;
  isAlreadyValidated?: boolean;
  height?: string | number;
};

const FormPhoneInput = <T extends FieldValues>(props: FormPhoneInputProps<T>) => {
  const { height, label, name, control, rules } = props;
  const { field, fieldState } = useController({ name, control, rules });

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['ca'].includes(iso2);
  });

  const phoneInput = usePhoneInput({
    defaultCountry: 'ca',
    value: field.value,
    onChange: (data) => {
      field.onChange(data.phone);
    },
    countries,
  });

  const handlePhoneValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const countryCode = phoneInput.country.dialCode;
    if (!e.target.value.startsWith(`+${countryCode}`)) {
      if (e.target.value < countryCode) {
        return;
      } else {
        field.onChange(`+${countryCode}${e.target.value}`);
      }
    } else {
      field.onChange(e.target.value);
    }
  };

  return (
    <FormControl mb={3} isInvalid={!!fieldState.invalid}>
      {label && <FormLabel fontWeight="400"> {label} </FormLabel>}
      <Flex align="center" pos="relative">
        <CountrySelector
          selectedCountry={phoneInput.country.iso2}
          onSelect={(country) => phoneInput.setCountry(country.iso2)}
          renderButtonWrapper={({ children, rootProps }) => (
            <Button bg="rgba(31, 42, 55, 0.05)" height={height || '44px'} {...rootProps} variant="outline" p={4} mr="8px">
              {children}
            </Button>
          )}
          countries={countries}
        />
        <Input
          value={field.value}
          onChange={(e) => {
            if (e.target.value.length < 15) {
              handlePhoneValueChange(e);
            }
          }}
          w="full"
          sx={{
            height: height || '44px',
            backgroundColor: 'rgba(31, 42, 55, 0.05)',
            '&:focus': {
              boxShadow: 'none',
              borderColor: fieldState.invalid ? 'red' : '#0F61D6',
            },
            '&::placeholder': {
              color: '#9b9b9b',
              fontSize: '14px',
              padding: '30px 0',
            },
          }}
          _invalid={{ borderColor: 'red' }}
        />
      </Flex>
      {fieldState.error && (
        <FormErrorMessage fontWeight="light" color="red">
          {fieldState.error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormPhoneInput;
