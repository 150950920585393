import { Text } from '@chakra-ui/react';

type Props = {
  msg: string;
};

const ErrorStateMsg = ({ msg }: Props) => {
  return <Text>{msg}</Text>;
};

export default ErrorStateMsg;
