import { useRouter } from 'next/router';
import { IoMdArrowBack } from 'react-icons/io';
import Btn from './btn/btn';

const BackBtn = () => {
  const router = useRouter();

  return (
    <Btn leftIcon={<IoMdArrowBack />} variant="solid" height={5} onClick={() => router.back()} mb={4}>
      Back
    </Btn>
  );
};

export default BackBtn;
