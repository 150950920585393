import { Header, Sidebar } from '@/components';
import { Box, Flex } from '@chakra-ui/react';

import { ReactNode } from 'react';

const DashboardLayout = ({ title, children, titleProps }: { title: string; children: ReactNode; titleProps?: any }) => {
  return (
    <Flex h="100vh">
      {/* Sidebar - Hidden on mobile */}
      <Sidebar screen="desktop" />

      {/* Main Content */}
      <Box flex={1} overflow="auto" p={4}>
        {/* Header */}
        <Header titleProps={titleProps} title={title} />
        {/*  */}
        {children}
      </Box>
    </Flex>
  );
};

export default DashboardLayout;
