import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

type FormInputProps<T extends FieldValues> = {
  label?: string;
  type?: string;
  id?: string;
  name: keyof T;
  placeholder?: string;
  isDisabled?: boolean;
  register: UseFormRegister<T>;
  error?: string;
  isInvalid?: boolean;
  validationMessage?: string;
  rules?: RegisterOptions<T>;
  icon?: React.ReactNode;
  height?: string | number;
};

const FormInput = <T extends FieldValues>(props: FormInputProps<T>) => {
  const { height, label, icon, isInvalid, validationMessage, name, register, rules, ...rest } = props;
  return (
    <FormControl borderRadius={8} mb={3} isInvalid={isInvalid}>
      {label && <FormLabel fontWeight="400">{label}</FormLabel>}
      <InputGroup>
        {icon && (
          <InputLeftElement height={height || '44px'} pointerEvents="none">
            {icon}
          </InputLeftElement>
        )}
        <Input
          {...rest}
          w="full"
          {...register(name as Path<T>, rules)}
          sx={{
            height: height || '44px',
            backgroundColor: 'rgba(31, 42, 55, 0.05)',
            '&:focus': {
              boxShadow: 'none',
              borderColor: isInvalid ? 'red' : '#0F61D6',
            },
            '&::placeholder': {
              color: '#9b9b9b',
              fontSize: '14px',
              padding: '30px 0',
            },
          }}
          height={height || { base: '44px', md: '56px' }}
          _invalid={{ borderColor: 'red' }}
        />
      </InputGroup>

      {isInvalid && (
        <FormErrorMessage fontWeight="light" color="red">
          {validationMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormInput;
