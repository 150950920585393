import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';
// FormRadioGroup.jsx
import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';

interface Option {
  label: string;
  value: string;
}

interface FormRadioGroup<T extends FieldValues> {
  name: FieldPath<T>;
  label?: string;
  options: Option[];
  control?: Control<T>;
  rules?: RegisterOptions<T>;
  placeholder?: string;
  height?: string | number;
}

const FormRadioGroup = <T extends FieldValues>({ name, label, options, control, rules }: FormRadioGroup<T>) => {
  const { field, fieldState } = useController({ name, control, rules });

  return (
    <FormControl mb={4} as="fieldset" isInvalid={fieldState.invalid}>
      {label && <FormLabel fontWeight="400">{label}</FormLabel>}

      <RadioGroup {...field}>
        <Stack direction="row">
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {fieldState.error && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormRadioGroup;
