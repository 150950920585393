import { LoadingSpinner } from '@/components';
import { get_token } from '@/utils/cookie-storage';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Index = () => {
  const router = useRouter();
  useEffect(() => {
    if (!!get_token()) router.replace('/account/dashboard');
    else router.push('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};
export default Index;
