import { Flex, Heading, Image, Show } from '@chakra-ui/react';

import { isPartner } from '@/utils/chakra-theme';

type Props = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: Props) => {
  return (
    <Flex mx="auto" minH="100svh">
      <Show above="sm">
        <Flex
          w={['45%']}
          height={'100%'}
          position="fixed"
          direction="column"
          flex="1"
          alignItems="center"
          justifyContent="center"
          gap={10}
          borderTopRightRadius={20}
          borderBottomRightRadius={20}
          background={
            isPartner
              ? 'linear-gradient(168.08deg, rgba(245, 143, 0, 0.8) 0.07%, #FCBB5F 20.83%, #FFCC85 66.25%, rgba(245, 143, 0, 0.8) 93.5%)'
              : 'linear-gradient(168.08deg, #001628 0.07%, #120D18 38.81%, #281938 66.25%, #001628 93.5%)'
          }
        >
          <Image src={isPartner ? 'images/logo-partner.svg' : '/images/logo-admin.svg'} w={300} alt="Logo" />
          <Heading fontSize={'2xl'} color={isPartner ? 'black' : 'secondary'}>
            Fresh Flavors Delivered Fast!
          </Heading>
        </Flex>
      </Show>
      <Flex ml={['unset', '45%']} alignItems="center" justifyContent="center" flex="1">
        {children}
      </Flex>
    </Flex>
  );
};

export default AuthLayout;
