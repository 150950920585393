import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form';

import Select from 'react-select';

interface Option {
  label: string;
  value: string;
}

interface FormSelectProps<T extends FieldValues> {
  name: FieldPath<T>;
  label?: string;
  options: Option[];
  isMulti?: false;
  control?: Control<T>;
  rules?: RegisterOptions<T>;
  placeholder?: string;
  height?: string | number;
  isLoading?: boolean;
}

const customStyles = {
  // @ts-ignore
  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 'unset',
      width: state.selectProps.width || '100%',
      height: state.selectProps.height || 44,
      color: state.selectProps.color || '#000',
      fontSize: state.selectProps.fontSize || '0.875rem',
      fontWeight: state.selectProps.fontWeight || 'normal',
      borderRadius: state.selectProps.borderRadius || 8,
      backgroundColor: state.selectProps.isDisabled ? '#F4F5F9' : state.selectProps.backgroundColor || 'rgba(31, 42, 55, 0.05)',
      borderColor: state.selectProps.isInvalid ? '#D14343 !important' : state.menuIsOpen || state.isFocused ? `rgb(55 65 81 / .1)` : 'rgb(55 65 81 / .1) !important',
      boxShadow: 'none !important',
      transition: '0.5s ease all !important',
    };
  },
  // @ts-ignore

  valueContainer: (provided) => {
    return {
      ...provided,
      height: '100%',
      textAlign: 'left',
    };
  },
  // @ts-ignore

  placeholder: (provided) => {
    return {
      ...provided,
      color: 'rgb(55 65 81 / 1)',
    };
  },
  // @ts-ignore

  singleValue: (provided) => {
    return {
      ...provided,
      color: 'inherit',
    };
  },
  // @ts-ignore

  indicatorsContainer: (provided) => {
    return {
      ...provided,
      height: '100%',
    };
  },
  // @ts-ignore

  indicatorSeparator: (provided) => {
    return {
      ...provided,
      display: 'none',
    };
  },
  // @ts-ignore
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: 'rgb(55 65 81 / 1)',
    };
  },
  // @ts-ignore

  menu: (provided) => {
    return {
      ...provided,
      backgroundColor: '#FEFEFE',
      color: 'rgb(55 65 81 / 1)',
      fontSize: '0.875rem',
    };
  },
  // @ts-ignore

  menuList: (provided) => {
    return {
      ...provided,
      maxHeight: 180,
      fontWeight: 'normal',
    };
  },
  // @ts-ignore

  option: (provided) => {
    return {
      ...provided,
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: 'left',
    };
  },
  // @ts-ignore

  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: 'var(--blue-light-color)',
      borderRadius: '1rem',
      padding: '4px',
      color: 'var(--text-default-color)',
    };
  },
  // @ts-ignore

  multiValueRemove: (provided) => {
    return {
      ...provided,
      backgroundColor: 'var(--text-default-color)',
      color: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
    };
  },
};

const FormSelect = <T extends FieldValues>({ label, options, name, control, rules, isMulti, ...rest }: FormSelectProps<T>) => {
  const { field, fieldState } = useController({ name, control, rules });

  return (
    <FormControl mb={4} isInvalid={fieldState.invalid}>
      {label && <FormLabel fontWeight="400">{label}</FormLabel>}
      <Select<Option>
        styles={customStyles}
        id={name}
        name={name}
        options={options}
        isMulti={isMulti}
        // @ts-ignore
        isInvalid={fieldState.invalid}
        value={options.find((opt) => opt.value === field.value)}
        onChange={(selectedOption) => field.onChange(selectedOption?.value || (isMulti ? [] : null))}
        {...rest}
      />
      {fieldState.error && (
        <FormErrorMessage fontWeight="normal" color="#D14343">
          {fieldState.error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormSelect;
