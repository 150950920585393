import { createStandaloneToast } from '@chakra-ui/react';
const { ToastContainer, toast: chakraToast } = createStandaloneToast();

const successToast = (title: string, description: string) =>
  chakraToast({
    title: title,
    description: description,
    status: 'success',
    duration: 2000,
    isClosable: true,
  });

const errorToast = (title: string, description: string) =>
  chakraToast({
    title: title,
    description: description,
    status: 'error',
    duration: 2000,
    isClosable: true,
  });

export { errorToast, successToast, ToastContainer };
