import { Box, Flex, HStack, Heading, Image, useMediaQuery } from '@chakra-ui/react';

import { Sidebar } from '@/components';

const Header = ({ title, titleProps }: { title: string; titleProps?: any }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex mb={[0, 8]} flexWrap={['wrap', 'nowrap']} justify="space-between" align="center" borderBottom={'1px solid #D9D9D9'} pb={{ md: 4 }}>
      <Flex w="full" mb={4} justify="space-between" align="center" display={['flex', 'none']}>
        <Sidebar screen="mobile" />
        <Image src="/images/logo-sm.png" alt="Logo" />
        <Box />
      </Flex>

      <Heading fontSize={['lg']} fontWeight={{ base: 'normal', md: 'bold' }} {...titleProps}>
        {title}
      </Heading>

      <HStack display={['flex']} spacing={4}>
        {/* <IconButton icon={<FcSearch size={20} />} variant="ghost" aria-label="search" /> */}
      </HStack>
    </Flex>
  );
};

export default Header;
