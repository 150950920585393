import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

function TableHead({ headings }: { headings: string[] }) {
  return (
    <Box as="thead">
      <Box as="tr">
        {headings.map((heading, i) => (
          <Box
            key={i}
            as="th"
            borderTopLeftRadius={i === 0 ? 'lg' : '0'}
            borderTopRightRadius={i === headings.length - 1 ? 'lg' : '0'}
            paddingY={3}
            bg="#E9E9E9"
            paddingX={4}
            textAlign="left"
            whiteSpace="nowrap"
            boxShadow="inset 0px -1px 0px #EDF0F2"
            fontSize=".9em"
            fontWeight={700}
            fontFamily="Open Sans, sans-serif"
          >
            {heading}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

interface TableBodyProps {
  data: { idField?: string; [key: string]: any }[];
  onRowClick?: (id: string) => void;
}

function TableBody({ data = [], onRowClick }: TableBodyProps) {
  function handleRowClick({ currentTarget }: React.MouseEvent<HTMLTableRowElement>) {
    if (onRowClick === undefined) return null;

    if (typeof onRowClick !== 'function') {
      throw new Error('Table row click handler is not a function');
    }

    var idField = currentTarget.dataset.id;
    if (idField === 'undefined') {
      throw new Error('Cannot find key property of table row');
    }

    if (typeof idField !== 'string') {
      throw new Error('Table row key is not a string');
    }

    onRowClick(idField);
  }

  return (
    <Box as="tbody" backgroundColor="#fff">
      {data.map((item, i) => {
        var { id, ...rest } = item;

        return (
          <tr key={i} data-id={id} onClick={handleRowClick}>
            {Object.values(rest).map((content, i) => (
              <Box key={i} as="td" paddingY={3} paddingX={4} whiteSpace="nowrap" boxShadow="inset 0px -1px 0px #EDF0F2" fontSize=".9em" fontFamily="sans-serif">
                {content}
              </Box>
            ))}
          </tr>
        );
      })}
    </Box>
  );
}

const StyledTable = styled.table`
  min-width: 100%;
  border-collapse: collapse;

  &[data-hover='true'] tbody tr:hover {
    cursor: pointer;
    /* box-shadow: inset 0 0px 4px 0 rgba(0, 0, 0, 0.12); */
  }
`;

interface TableProps {
  headings: string[];
  data: { idField?: string; [key: string]: any }[];
  onRowClick?: (id: string) => void;
}

function Table({ headings, data, onRowClick }: TableProps) {
  return (
    <Box overflow="auto">
      <Box display="inline-block" minWidth="100%" verticalAlign="middle" overflow="hidden">
        <StyledTable data-hover={typeof onRowClick === 'function'}>
          {headings && <TableHead headings={headings} />}
          <TableBody data={data} onRowClick={onRowClick} />
        </StyledTable>
      </Box>
    </Box>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  headings: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default Table;
