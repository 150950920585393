import { Button, ButtonProps } from '@chakra-ui/react';

import { ReactNode } from 'react';

interface Props extends ButtonProps {
  children: ReactNode;
  look?: 'primary-filled' | 'primary-outlined' | 'secondary-filled' | 'secondary-outlined' | 'tertiary-filled' | 'tertiary-outlined';
}

const Btn = ({ children, ...props }: Props) => {
  return (
    <Button height={53} colorScheme="brand" rounded="full" py={4} {...props}>
      {children}
    </Button>
  );
};

export default Btn;
