import { isAdmin, userType } from '@/utils/chakra-theme';
import { Box, Divider, Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Heading, Icon, IconButton, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { FaBicycle, FaClipboard, FaStore, FaWallet } from 'react-icons/fa6';
import { IoLogInOutline, IoLogOutOutline } from 'react-icons/io5';

import { handleLogout } from '@/utils/auth';
import { Globe } from '@phosphor-icons/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AiOutlineNotification } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import { GrBraille } from 'react-icons/gr';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { LuSettings } from 'react-icons/lu';

const menuItems = [
  { icon: GrBraille, label: 'Dashboard', link: '/account/dashboard', roles: ['admin', 'partner'] },
  { icon: IoLogInOutline, label: 'Onboarding', link: '/account/onboard', roles: ['admin', 'partner'] },
  { icon: FaClipboard, label: 'Orders', link: '/account/orders', roles: ['admin', 'partner'] },
  { icon: FaUserCircle, label: 'Customers', link: '/account/customers', roles: ['admin', 'partner'] },
  { icon: Globe, label: 'Regional Admins', link: '/account/regional-admins', roles: ['admin'] },
  { icon: FaStore, label: 'Vendors', link: '/account/vendors', roles: ['admin', 'partner'] },
  { icon: FaBicycle, label: 'Riders', link: '/account/riders', roles: ['admin', 'partner'] },
  { icon: FaWallet, label: 'Earnings', link: '/account/earnings', roles: ['admin', 'partner'] },
  { icon: AiOutlineNotification, label: 'Ads Management', link: '/account/ads', roles: ['admin'] },

  { icon: LuSettings, label: 'Settings', link: '/account/settings', roles: ['admin', 'partner'] },
  { icon: IoLogOutOutline, label: 'Logout', link: '', roles: ['admin', 'partner'] },
];

const MenuItem = ({ icon, label, link, isActive }: { icon: any; label: string; link: string; isActive: boolean }) => {
  return (
    <Link href={link}>
      <HStack p={2} borderLeftRadius="xl" _hover={{ bg: 'whiteAlpha.400' }} cursor="pointer" bg={isActive ? 'white' : ''}>
        <Icon backgroundColor="white" width={8} height={8} padding={2} borderRadius="lg" color={isAdmin ? 'primary' : 'secondary'} as={icon} />
        <Text fontSize="sm" color={isActive && isAdmin ? 'primary' : isActive && !isAdmin ? 'secondary' : 'inherit'}>
          {label}
        </Text>
      </HStack>
    </Link>
  );
};

const Sidebar = ({ screen }: { screen: 'mobile' | 'desktop' }) => {
  const router = useRouter();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItemsWithActive = menuItems.map((item) => ({ ...item, isActive: router.pathname.includes(item.link) }));

  if (screen === 'mobile') {
    return (
      <>
        <IconButton onClick={onOpen} icon={<HiOutlineMenuAlt2 size={20} />} aria-label="region" />

        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
          <DrawerOverlay />
          <DrawerContent bg="primary" color="white">
            <DrawerCloseButton />
            <DrawerHeader>
              <Heading size="md">{isAdmin ? 'Foodbay Admin' : 'Foodbay Partner'}</Heading>
            </DrawerHeader>

            <VStack ml={4} spacing={4} align="stretch">
              {menuItemsWithActive
                .slice(0, -2)
                .filter((item) => item.roles.includes(userType))
                .map((item, index) => {
                  return <MenuItem key={index} {...item} />;
                })}
              <Divider bg="white" />
              {menuItemsWithActive.slice(-2).map((item, index) => {
                if (item.label === 'Logout') {
                  return (
                    <Box key={index} onClick={() => handleLogout()}>
                      <HStack p={2} borderLeftRadius="xl" _hover={{ bg: 'whiteAlpha.400' }} cursor="pointer">
                        <Icon backgroundColor="white" width={8} height={8} padding={2} borderRadius="lg" color="secondary" as={item.icon} />
                        <Text fontSize="sm" color="inherit">
                          {item.label}
                        </Text>
                      </HStack>
                    </Box>
                  );
                }
                return <MenuItem key={index} {...item} />;
              })}
            </VStack>
          </DrawerContent>
        </Drawer>
      </>
    );
  }
  return (
    <Box display={{ base: 'none', md: 'block' }} w="240px" bg="primary" color="white" pl={4} py={4}>
      <VStack spacing={10} align="stretch">
        <Heading size="md">{isAdmin ? 'Foodbay Admin' : 'Foodbay Partner'}</Heading>

        <VStack spacing={4} align="stretch">
          {menuItemsWithActive
            .slice(0, -2)
            .filter((item) => item.roles.includes(userType))
            .map((item, index) => {
              return <MenuItem key={index} {...item} />;
            })}
          <Divider bg="white" />
          {menuItemsWithActive.slice(-2).map((item, index) => {
            if (item.label === 'Logout') {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    handleLogout();
                    router.push('/login');
                  }}
                >
                  <HStack p={2} borderLeftRadius="xl" _hover={{ bg: 'whiteAlpha.400' }} cursor="pointer">
                    <Icon backgroundColor="white" width={8} height={8} padding={2} borderRadius="lg" color="secondary" as={item.icon} />
                    <Text fontSize="sm" color="inherit">
                      {item.label}
                    </Text>
                  </HStack>
                </Box>
              );
            }
            return <MenuItem key={index} {...item} />;
          })}
        </VStack>
      </VStack>
    </Box>
  );
};

export default Sidebar;
