import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FormControl, FormErrorMessage, FormLabel, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, useTheme } from '@chakra-ui/react';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';

import { useState } from 'react';

type FormPasswordInputProps<T extends FieldValues> = {
  label?: string;
  id?: string;
  name: keyof T;
  placeholder?: string;
  isDisabled?: boolean;
  register: UseFormRegister<T>;
  error?: string;
  isInvalid?: boolean;
  validationMessage?: string;
  rules?: RegisterOptions<T>;
  icon?: React.ReactNode;
  height?: string | number;
};

const FormPasswordInput = <T extends FieldValues>(props: FormPasswordInputProps<T>) => {
  const { colors } = useTheme();
  const { height, label, icon, isInvalid, validationMessage, name, register, rules, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => setShowPassword(!showPassword);

  return (
    <FormControl borderRadius={8} mb={5} isInvalid={isInvalid}>
      {label && <FormLabel fontWeight="400"> {label} </FormLabel>}
      <InputGroup>
        {icon && (
          <InputLeftElement height={height || '44px'} pointerEvents="none">
            {icon}
          </InputLeftElement>
        )}
        <Input
          type={showPassword ? 'text' : 'password'}
          {...rest}
          w="full"
          {...register(name as Path<T>, rules)}
          sx={{
            height: height || '44px',
            backgroundColor: 'rgba(31, 42, 55, 0.05)',
            '&:focus': {
              boxShadow: 'none',
              borderColor: isInvalid ? 'red' : '#0F61D6',
            },
            '&::placeholder': {
              color: '#9b9b9b',
              fontSize: '14px',
              padding: '30px 0',
            },
          }}
          _invalid={{ borderColor: 'red' }}
        />
        <InputRightElement height={height || '44px'}>
          <IconButton
            color={colors.icon}
            aria-label={showPassword ? 'Hide password' : 'Show password'}
            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
            onClick={handleClick}
            variant="ghost"
            tabIndex={-1}
            size="lg"
            mr={4}
          />
        </InputRightElement>
      </InputGroup>

      {isInvalid && (
        <FormErrorMessage fontWeight="light" color="red">
          {validationMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormPasswordInput;
