import { Flex, Image, Text } from '@chakra-ui/react';

export default function EmptyState({ message }: { message: string }) {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <Image w="350px" src="/images/emptystate.png" alt="empty_state" />
      <Text fontSize="20px" mt={10} fontWeight="bold">
        {message}
      </Text>
    </Flex>
  );
}
