import { get_token } from '@/utils/cookie-storage';
import { jwtDecode } from 'jwt-decode';

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function xhrRequest({
  method,
  url,
  data,
  onProgress,
  onReadyStateChange,
}: // onCancel,
{
  method: string;
  url: string;
  data: any;
  onProgress: (e: any) => void;
  onReadyStateChange: (e: any) => unknown;
}) {
  const xhr = new XMLHttpRequest();

  xhr.open(method, url, true);

  xhr.upload.onprogress = onProgress;

  xhr.onreadystatechange = onReadyStateChange;

  // xhr.upload.onabort = onCancel;

  xhr.send(data);
  return xhr;
}

export async function fetchWithRetry(url: string, options: RequestInit = {}, retries: number = 3, backoff: number = 300): Promise<Response> {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response; // Return the successful response
    } catch (error) {
      if (attempt === retries) {
        throw error; // Throw the error if all retries are exhausted
      }
      await new Promise((resolve) => setTimeout(resolve, backoff * attempt)); // Exponential backoff
    }
  }
  // This line should never be reached, but TypeScript requires it
  throw new Error('fetchWithRetry failed unexpectedly');
}

export const getErrorMessage = (error: any): string => {
  if (error?.response) {
    /* The request was made
    the server responded with a status code that falls out of range of 2xx */
    console.log('Err', error.response.data.message || error.response.data.error || error.response.data.detail || 'Something went wrong');
    return error.response.data.message || error.response.data.error || error.response.data.detail || 'Something went wrong';
  }

  if (error?.data) {
    // console.log('Err', error.data.message);
    return error.data.message;
  }

  if (error?.message) {
    // Something happened in setting up the request that triggered an error
    console.log('Err', error.message);
    return error.message;
  }

  if (error?.request) {
    // The request was made but no response was received
    console.log('Err', error.RequestInit);
    return 'Something went wrong';
  }
  return '';
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error(`Invalid date string: ${dateString}`);
    return 'Invalid Date';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  try {
    return new Intl.DateTimeFormat('en-US', options).format(date);
  } catch (error) {
    console.error(`Error formatting date: ${error}`);
    return 'Date Formatting Error';
  }
}
export const formatDateTime = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return date.toLocaleString('en-GB', options).replace(',', ' |');
};

export const textToEllipses = (text: string = '', len: number = 20): string => {
  if (text && text.length > len) {
    return `${text.trim().substring(0, len)}...`;
  }
  return text;
};

export const parseJwt = () => {
  const token = get_token();
  if (token) {
    const res = jwtDecode(token);
    console.log(res);
    return res; //as JWTToken;
  } else return;
};

export function optionsToReactSelectFormat(options = [], key = { label: '', value: '' }) {
  if (key.label && key.value) {
    return options.map((option) => ({
      label: option[key.label],
      value: option[key.value],
    }));
  }

  throw new Error('optionsToReactSelectFormat function must contain a "key" parameter with "label" and "value" properties');
}

export const formatCustomDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  const formattedDate = date.toLocaleDateString('en-GB', options).replace(',', '');
  const [datePart, timePart] = formattedDate.split(' ');
  const amPm = date.getHours() >= 12 ? 'pm' : 'am';
  return `${datePart} | ${timePart} ${amPm}`;
};
export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}
