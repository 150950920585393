import { Flex, Spinner } from '@chakra-ui/react';

const LoadingSpinner = () => {
  return (
    <Flex alignItems="center" justify="center" w="full" h="full">
      <Spinner />
    </Flex>
  );
};

export default LoadingSpinner;
