import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import { SearchIcon } from '@chakra-ui/icons';

type Props = {
  value: string;
  onChange: (e: string) => void;
};

const SearchInput = ({ value, onChange }: Props) => {
  return (
    <InputGroup w={{ base: 'full', md: '238px' }} mb={5}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input placeholder="Search..." value={value} onChange={(e) => onChange(e.target.value)} />
    </InputGroup>
  );
};

export default SearchInput;
