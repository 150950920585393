import { get_token, remove_token } from '@/utils/cookie-storage';
import { JwtPayload, jwtDecode } from 'jwt-decode';

import { makeVar } from '@apollo/client';

const token = get_token();
const isLoggedIn = makeVar(!!token);

export const getLoggedInUser = () => {
  return {
    partnerId: parseJwt()?.['https://hasura.io/jwt/claims']?.['x-hasura-partner-id'],
    regionId: parseJwt()?.['https://hasura.io/jwt/claims']?.['x-hasura-region-id'],
    subRegionId: parseJwt()?.['https://hasura.io/jwt/claims']?.['x-hasura-sub-region-id'],
  };
};

interface JWTToken extends JwtPayload {
  id: string;
  'https://hasura.io/jwt/claims': {
    'x-hasura-partner-id': string;
    'x-hasura-region-id'?: string;
    'x-hasura-sub-region-id'?: string;
  };
}

export const parseJwt = () => {
  const token = get_token();
  if (token) {
    const res = jwtDecode(token);
    return res as JWTToken;
  } else return;
};

export const handleLogout = () => {
  remove_token();
};
