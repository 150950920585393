import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client';
import { FC, ReactNode } from 'react';

import { get_token } from '@/utils/cookie-storage';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloProvider } from '@apollo/client/react';

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
});

export const API_URL = process.env.NEXT_PUBLIC_FOODBAY_URL;
export const GQLClient = () => {
  const token = get_token();
  const headers = {
    'content-type': 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  const httpLink = new HttpLink({
    headers,
    uri: API_URL,
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    link: from([retryLink, httpLink]),
  });
};

interface Props {
  children: ReactNode;
}

const ApolloClientProvider: FC<Props> = ({ children }) => {
  const client = GQLClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
